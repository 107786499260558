import { useSearchParams } from '@kwara/lib/src/hooks/useSearchParams';

import { useMember } from './useMember';
import { useV1LoanAdd } from './useV1LoanAdd';
import { useLegacyLoanAdd } from './useLegacyLoanAdd';
import { useAuth } from '../../../hooks/useAuth/useAuth';

type Params = {
  memberId: string;
};

export function useLoanAdd() {
  const auth = useAuth();
  const searchParams = useSearchParams<Params>();

  const memberIdParam = searchParams.get('memberId');

  const r = useMember({ id: memberIdParam, opts: { enabled: !!memberIdParam?.length } });
  const { createLoan: createV1Loan, createdLoan: createdV1Loan } = useV1LoanAdd();
  const { createLoan: createLegacyLoan, createdLoan: createdLegacyLoan } = useLegacyLoanAdd();

  const isV1 = auth.isV1();
  const createLoan = isV1 ? createV1Loan : createLegacyLoan;
  const createdLoan = isV1 ? createdV1Loan : createdLegacyLoan;

  return { r, createLoan, createdLoan };
}
