import { attr, belongsTo, hasMany, hasOne } from 'spraypaint';
import some from 'lodash/fp/some';
import get from 'lodash/fp/get';
import find from 'lodash/fp/find';
import pipe from 'lodash/fp/pipe';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import isFinite from 'lodash/fp/isFinite';
import _result from 'lodash/fp/result';
import includes from 'lodash/fp/includes';
import join from 'lodash/fp/join';
import axios from 'axios';

import { sumBy, add } from '@kwara/lib/src/currency';
import { Logger } from '@kwara/lib/src/logger';
import { blobHeaders } from '@kwara/lib/src/fileDownload';
import { httpErrorHandler } from '@kwara/lib/src/services';

import { InferredModel, ValueOf } from 'GlobalTypes';
import { fields as remittanceFields } from '../Remittance';
import Schedule from '../Schedule';
import { ModelErrors } from '../..';
import Base, { IncludesT } from '../Base';
import {
  snakeCaseObjectKeys,
  createCancellablePromise,
  SearchCancellation,
  Guarantor,
  GuaranteeType,
  PotentialGuarantorType,
  CollateralT,
  TransactionChannelT,
  PeriodUnitsT
} from '../..';
import createModelErrors, { createErrorsFromApiResponse } from '../createModelErrors';
import filterEmptyValues from '../../lib/filterEmptyValues';
import { LoanApplication, LoanApplicationT } from '../LoanApplication';

import { LoanPurpose } from '../LoanPurpose';
import V1 from '../v1';
import { MemberType } from '../Member';
import { toDurationObjectUI } from '../util';
import { LoanProductType } from '../LoanProduct';
import { RepaymentType } from '../Repayment';
import { LoanRemittance, LoanRemittanceT } from '../Remittance';
import { FeeApplicationT } from '../LoanApplication';
import { LoanRefinancingT } from '../v1/Loans';

export const DEFAULT_REPAYMENT_PERIOD = 1;
export const DEFAULT_REPAYMENT_PERIOD_UNIT = 'MONTHS';
// These are the native Loan states Mambu accepts,
// thus the ones used to populate the state
//  filter on the Loans list page, see ch11336
export const LoanBaseStates = {
  ACTIVE: 'ACTIVE',
  ACTIVE_IN_ARREARS: 'ACTIVE_IN_ARREARS',
  APPROVED: 'APPROVED',
  CLOSED: 'CLOSED',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  PARTIAL_APPLICATION: 'PARTIAL_APPLICATION'
};

export const V1LoanBaseStates = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  APPROVED_AWAITING_DISBURSEMENT: 'APPROVED_AWAITING_DISBURSEMENT',
  LIVE: 'LIVE',
  REJECTED: 'REJECTED',
  CLOSED_REPAID: 'CLOSED_REPAID',
  CLOSED_WRITTEN_OFF: 'CLOSED_WRITTEN_OFF'
};

/**
 * This is a LoanBase state but we are not adding
 * it to LoanBaseStates because its grouped and
 * categorized as ACTIVE State
 */
const ACTIVE_PARTIALLY_DISBURSED = 'ACTIVE_PARTIALLY_DISBURSED';

// These are the loan states used on the Kwara platform
export const LoanStates = {
  ...LoanBaseStates,
  LIVE: 'LIVE',
  CLOSED_REJECTED: 'CLOSED_REJECTED',
  CLOSED_WRITTEN_OFF: 'CLOSED_WRITTEN_OFF',
  CLOSED_RESCHEDULED: 'CLOSED_RESCHEDULED',
  CLOSED_WITHDRAWN: 'CLOSED_WITHDRAWN',
  CLOSED_REPAID: 'CLOSED_REPAID'
};

export const LoanEvents = Object.freeze({
  APPROVE: 'approve',
  DISBURSE: 'disburse',
  REJECT: 'reject',
  SOFT_REJECT: 'soft_reject',
  WRITE_OFF: 'write_off',
  MARK_AS_DISBURSED: 'mark_as_disbursed'
});

export type LoanBaseState = ValueOf<typeof LoanBaseStates>;
export type LoanState = ValueOf<typeof LoanStates>;
export type LoanEvent = ValueOf<typeof LoanEvents>;

type UiRepaymentFrequency = {
  loanDuration: number;
  period: number;
};

export type ApiRepaymentPeriodT = {
  unit: PeriodUnitsT;
  period: number;
  installments: number;
};

export type RepaymentDetailsT = {
  direct_debit_bank?: string;
  direct_debit_bank_account?: string;
  direct_debit_branch?: string;
  direct_debit_collecting_bank?: string;
  repayment_amount?: string;
  repayment_collection_date?: string;
  repayment_collection_frequency?: string;
  repayment_fee_amount?: string;
  repayment_mode: string;
};

export type DisbursementBankDetailsT = {
  bank?: string;
  bank_branch?: string;
  account_number?: string;
};

type Note = {
  notes: string | null;
};

export type DisbursalInfo = {
  amount: number;
  valueDate: Date;
  paymentMethodId?: string; // for v1 api
  glLinkId?: string; // for v1 api
  paymentMethod?: TransactionChannelT; // for legacy api
  onlinePayoutDetails?: {
    funds_transfer_method: string;
    account_number: string;
  };
  reference?: string;
  notes?: string;
  bankName?: string;
  bankBranch?: string;
  chequeNumber?: string;
  drawer?: string;
  accountNumber?: string;
  accountId?: string;
  bankGlId?: string;
  feeApplications?: { feeId: string; amount?: string }[];
};

type TopupPayload = {
  data: {
    attributes: {
      topup_amount: number;
      expected_disbursement_date: string;
      first_repayment_date: string;
      repayment_installments: number;
      repayment_period: number;
      repayment_period_unit: string;
      write_off_amounts: {
        fee: number;
        interest: number;
        penalty: number;
      };
      notes?: string;
    };
  };
};

type V1PayOffParams = {
  notes?: string;
  transactionDate?: string;
  paymentMethodId: string;
};

export const calculateNumberInstallments = ({ loanDuration, period }: UiRepaymentFrequency): number => {
  return Math.floor(loanDuration / period);
};

export const fields = Object.freeze({
  disbursement: {
    mode: 'disbursementMode',
    bank: 'disbursementBankDetails.bank',
    branch: 'disbursementBankDetails.bank_branch',
    account: 'disbursementBankDetails.account_number'
  }
});

const Loan = Base.extend({
  static: {
    jsonapiType: 'loans',

    reschedule(id: string) {
      return Loan.extend({
        static: {
          endpoint: `/loans/${id}/reschedule`
        },
        attrs: {
          restructureDetails: attr()
        }
      });
    },

    refinance(id: string) {
      return Loan.extend({
        static: {
          endpoint: `/loans/${id}/refinance`
        }
      });
    },

    search({ term = '' }: { term: string }) {
      const cancelSource = axios.CancelToken.source();

      if (term === '') {
        const promise = Loan.where({ state: 'ACTIVE_IN_ARREARS' })
          .includes(['member', 'product'])
          .all()
          .then(r => r.data);

        return createCancellablePromise(promise, cancelSource);
      }

      const url = `${Loan.fullBasePath()}/search?q=${escape(term)}&filter[state]=ACTIVE_IN_ARREARS&type=loan`;

      //TO DO: Activate search url below once endpoint is created

      // const urlWithArrearsFilter = `${Loan.fullBasePath()}/search?q=${escape(
      //   term
      // )}&filter[state]=ACTIVE_IN_ARREARS&filter[days_in_arrears]=${daysInArrears}type=loan`;

      const options = {
        ...Loan.fetchOptions(),
        cancelToken: cancelSource.token
      };

      const promise = axios.get(url, options).then(
        response => {
          if (response.status > 299) {
            throw new Error(`Response not OK`);
          }

          const jsonResult = response.data;

          return Promise.all(
            jsonResult.data.map(record => {
              return Loan.includes(['member', 'product', 'repayments'])
                .find(record.id)
                .then(r => r.data);
            })
          );
        },
        err => {
          if (axios.isCancel(err)) {
            throw new SearchCancellation();
          }

          throw err;
        }
      );

      return createCancellablePromise(promise, cancelSource);
    }
  },
  attrs: {
    amount: attr(),
    name: attr(),
    firstRepaymentDate: attr(),

    // Read Only Fields
    fees: attr({ persist: false }),
    penalties: attr({ persist: false }),
    interest: attr({ persist: false }),
    principal: attr({ persist: false }),
    state: attr({ persist: false }),
    interestRate: attr({ persist: false }),
    interestSettings: attr({ persist: false }),

    // Represents the duration of the loan in
    // string format i.e. P10M
    duration: attr(),
    purpose: attr(),
    specification: attr(),
    repaymentFrequency: attr(),

    totalBalance: attr(),
    totalDue: attr(),
    totalPaid: attr(),
    accruedInterest: attr(),

    anticipatedDisbursementDate: attr(),
    anticipatedFirstRepaymentDate: attr(),
    submittedAt: attr(),
    disbursementDate: attr(),
    firstPaymentDate: attr(),

    pendingDisbursementAmount: attr(),

    // UI Field representing the numerical value of the loan duration
    loanDuration: attr({ persist: false }),

    member: belongsTo(),
    dbMember: belongsTo('members'),
    product: belongsTo('loan_products'),
    collaterals: hasMany(),
    transactions: hasMany(),
    remittance: hasOne({ type: LoanRemittance }),
    loanPurpose: hasOne({ type: LoanPurpose }),
    loanApplication: hasOne({ type: LoanApplication }),
    repayments: hasMany('repayments'),
    charges: hasMany(),
    expectedLoanRefinancings: hasMany({ type: V1.Loans.Refinancing }),

    // Create
    accountHolderId: attr(),
    productId: attr(),
    repaymentInstallments: attr(),
    repaymentPeriod: attr(),
    repaymentPeriodUnit: attr(),
    payOffLoans: attr(),
    feeApplications: attr(),
    loanClassificationId: attr(),
    applicationNotes: attr(),

    // write
    guarantors: hasMany(),
    guarantorshipRequests: attr(),
    // read
    guarantees: hasMany(),

    disbursementMode: attr(),
    disbursementBankDetails: attr(),
    //v1_api only
    memberName: attr(),
    disbursedAt: attr(),
    recommendedAmount: attr(),
    disbursedAmount: attr(),
    inArrears: attr(),
    feesAtDisbursementCharged: attr(),
    totalExpectedAmountToRefinance: attr(),
    expectedNetDisbursalAmount: attr(),
    annualInterestRate: attr()
  },
  methods: {
    deserialize() {
      // This field is stored as a string in Mambu, but needs
      // to be a date for the DatePicker to understand it
      const startDate = get(remittanceFields.remittance.startDate, this);
      if (startDate) {
        this.remittance.startDate = new Date(startDate);
      }

      // The order of these assignments is important! We need access to
      // the repaymentPeriod object the api returns to define
      // repaymentPeriodUnit before we then reassign repaymentPeriod
      // to fit the shape the UI is expecting.
      this.repaymentPeriodUnit = get('repaymentPeriod.unit', this);
      this.repaymentPeriod = get('repaymentPeriod.count', this);
      this.loanDuration = get('value', toDurationObjectUI(this.duration));

      // wtf ch8924
      this.guarantors = map(g => {
        const inst = new Guarantor(g);
        inst.isPersisted = true;
        inst.memberId = get('member.id', g);
        return inst;
      }, this.guarantees);

      if (this.inArrears) {
        this.state.current = 'ACTIVE_IN_ARREARS';
      }

      return this;
    },
    hasNoCollaterals() {
      return isEmpty(this.collaterals);
    },
    hasNoGuarantors() {
      return isEmpty(this.guarantors);
    },
    hasNoSecurities() {
      return this.hasNoCollaterals() && this.hasNoGuarantors();
    },
    sumOfGuarantees() {
      return sumBy('amount', this.guarantees);
    },
    sumOfCollaterals() {
      return sumBy('amount', this.collaterals);
    },
    sumOfSecurities() {
      return Number(add(this.sumOfGuarantees(), this.sumOfCollaterals()));
    },
    isPaidOff() {
      return Number(this.totalBalance) <= 0;
    },
    isBridgingProduct() {
      return get('product.isBridgingProduct', this);
    },
    interestRatePercent() {
      return isFinite(get('interestRate.percentage', this)) ? this.interestRate.percentage / 100 : null;
    },
    isEditable() {
      return [LoanStates.PENDING_APPROVAL, LoanStates.ACTIVE, LoanStates.ACTIVE_IN_ARREARS].includes(
        this.state.current
      );
    },
    canDisburse(maxDisbursers) {
      const hasReachedDisbursementThreshold = this.loanApplication?.disbursement?.appraisals?.length >= maxDisbursers;
      const isEventPermitted =
        this.isEventPermitted(LoanEvents.DISBURSE) || this.isEventPermitted(LoanEvents.MARK_AS_DISBURSED);

      return (
        !this.canDisburseTopedUpLoan() &&
        !hasReachedDisbursementThreshold &&
        isEventPermitted &&
        !this.isBridgingProduct()
      );
    },
    canWriteOff() {
      return this.isEventPermitted(LoanEvents.WRITE_OFF) && !this.isBridgingProduct();
    },
    canDisburseTopedUpLoan() {
      return this.state.current === ACTIVE_PARTIALLY_DISBURSED;
    },

    isUnreschedulableLoan() {
      // Hack Alert: Loans with frequency and duration equal
      // to 1 day are not able to be rescheduled
      // Link on Slack:
      // https://kwara.slack.com/archives/C8EPTLL7K/p1582889413012000
      return this.repaymentFrequency === 'P1D' && this.duration === 'P1D';
    },
    canReschedule() {
      return (
        [LoanStates.ACTIVE, LoanStates.ACTIVE_IN_ARREARS].includes(this.state.current) &&
        !this.isUnreschedulableLoan() &&
        !this.isBridgingProduct()
      );
    },
    canRefinance() {
      return (
        [LoanStates.ACTIVE, LoanStates.ACTIVE_IN_ARREARS].includes(this.state.current) && !this.isBridgingProduct()
      );
    },
    isRefinancing() {
      return !!_result('loanApplication.isRefinancing', this);
    },
    canMakeRepayment() {
      return (
        // if the loan has been paid off we disallow adding repayments [ch7874]
        this.isApproved() && !this.isPaidOff() && !this.isBridgingProduct()
      );
    },
    isApproved() {
      return [LoanStates.ACTIVE, LoanStates.ACTIVE_IN_ARREARS, LoanStates.LIVE].includes(this.state.current);
    },
    isPendingApproval() {
      return this.state.current === LoanStates.PENDING_APPROVAL;
    },
    isClosed() {
      return includes('CLOSED', this.state.current);
    },
    canAddFee() {
      return (
        this.principal.balance > 0 &&
        (this.state.current === LoanStates.ACTIVE || this.state.current === LoanStates.ACTIVE_IN_ARREARS) &&
        !this.isBridgingProduct()
      );
    },

    canTopup() {
      return [LoanStates.ACTIVE, LoanStates.ACTIVE_IN_ARREARS].includes(this.state.current) && this.product.activated;
    },

    canUndoTopup() {
      return [ACTIVE_PARTIALLY_DISBURSED].includes(this.state.current);
    },

    hasDisbursementRecord() {
      return !!get('loanApplication.disbursement', this);
    },

    isEventPermitted(event: LoanEvent) {
      return some({ name: event }, this.state.permitted_events);
    },
    async getOutstandingBalance() {
      try {
        const { data = {} } = await Schedule.find(this.id);
        // Consider the 1st non-paid repayment as the one determining the outstanding amount [ch2984]
        // https://app.clubhouse.io/getkwara/story/2984/display-member-loan-repayment-amount-field-in-the-input-placeholder
        const repayment = find(o => o.state !== 'PAID', data.repayments);
        return repayment ? repayment.outstanding() : 0;
      } catch (e) {
        Logger.error('Error calculating outstanding balance', JSON.stringify(e));
      }
    },

    async approve({
      application_notes,
      disbursement_type,
      recommended_amount,
      anticipated_first_repayment_date,
      anticipated_disbursement_date
    }: {
      application_notes: null | string;
      disbursement_type: null | 'normal' | 'early_release' | 'staggered';
      recommended_amount: null | number;
      anticipated_first_repayment_date: null | Date;
      anticipated_disbursement_date: null | Date;
    }): Promise<boolean> {
      const params = filterEmptyValues({
        application_notes,
        disbursement_type,
        recommended_amount,
        anticipated_first_repayment_date,
        anticipated_disbursement_date
      });

      if (this.isEventPermitted(LoanEvents.APPROVE)) {
        return await this.transition(LoanEvents.APPROVE, params);
      }

      this.errors = createModelErrors({
        base: 'APP_LOAN_INVALID_STATE_TRANSITION'
      });

      return false;
    },
    async reject({ comment }: { comment?: null | string } = {}): Promise<boolean> {
      if (this.isEventPermitted(LoanEvents.REJECT)) {
        return await this.transition(LoanEvents.REJECT, { comment });
      }

      this.errors = createModelErrors({
        base: 'APP_LOAN_INVALID_STATE_TRANSITION'
      });

      return false;
    },
    async softReject({ comment }: { comment?: string | null } = {}): Promise<boolean> {
      if (this.isEventPermitted(LoanEvents.SOFT_REJECT)) {
        return await this.transition(LoanEvents.SOFT_REJECT, { comment });
      }

      this.errors = createModelErrors({
        base: 'APP_LOAN_INVALID_STATE_TRANSITION'
      });

      return false;
    },

    async disburse(info: DisbursalInfo): Promise<string | null> {
      if (this.isEventPermitted(LoanEvents.DISBURSE)) {
        const url = `${Loan.url(this.id)}/disburse`;
        const attributes = pipe(filterEmptyValues, snakeCaseObjectKeys)(info);
        const options = {
          ...Loan.fetchOptions(),
          method: 'PUT',
          body: JSON.stringify({ data: { attributes } })
        };

        try {
          const response = await window.fetch(url, options);
          const body = await response.json();
          if (!response.ok) {
            this.errors = createErrorsFromApiResponse(body);
            return null;
          }
          return get('data.attributes.state', body);
        } catch (errors) {
          Logger.error('Error disbursing loan', JSON.stringify(errors));
          this.errors = createModelErrors({
            base: 'APP_NETWORK_ERROR'
          });

          return null;
        }
      }
    },

    async disburseV1(info: DisbursalInfo): Promise<string | null> {
      if (this.isEventPermitted(LoanEvents.DISBURSE) || this.isEventPermitted(LoanEvents.MARK_AS_DISBURSED)) {
        const url = `${Loan.url(this.id)}/state`;
        const attributes = pipe(filterEmptyValues, snakeCaseObjectKeys)({ ...info, event: 'disburse' });
        const options = {
          ...Loan.fetchOptions(),
          method: 'PUT',
          body: JSON.stringify({ data: { attributes } })
        };

        try {
          const response = await window.fetch(url, options);
          const body = await response.json();

          if (!response.ok) {
            this.errors = createErrorsFromApiResponse(body);

            return null;
          }

          return get('data.attributes.state.current', body);
        } catch (errors) {
          Logger.error('Error disbursing loan', JSON.stringify(errors));

          this.errors = createModelErrors({ base: 'APP_NETWORK_ERROR' });

          return null;
        }
      }
    },

    async writeOff(notes: null | Note) {
      if (this.isEventPermitted(LoanEvents.WRITE_OFF)) {
        return await this.transition(LoanEvents.WRITE_OFF, notes);
      }

      this.errors = createModelErrors({
        base: 'UI_APP_LOAN_WRITE_OFF_INVALID_STATE'
      });

      return false;
    },
    async payOff(params) {
      const url = `${Loan.url(this.id)}/payoff`;
      const attributes = pipe(filterEmptyValues, snakeCaseObjectKeys)(params);

      const options = {
        ...Loan.fetchOptions(),
        method: 'POST',
        body: JSON.stringify({ data: { attributes } })
      };

      try {
        const response = await window.fetch(url, options);
        if (!response.ok) {
          const body = await response.json();
          this.errors = createErrorsFromApiResponse(body);

          return false;
        }

        return true;
      } catch (errors) {
        Logger.error('Error paying off loan account', JSON.stringify(errors));
        this.errors = createModelErrors({
          base: 'APP_NETWORK_ERROR'
        });

        return false;
      }
    },
    async v1PayOff(params: V1PayOffParams) {
      const url = `${Loan.url(this.id)}/state`;
      const parsedParams: Object = pipe(filterEmptyValues, snakeCaseObjectKeys)(params);

      const options = {
        ...Loan.fetchOptions(),
        method: 'PUT',
        body: JSON.stringify({
          data: {
            attributes: {
              ...parsedParams,
              event: 'payoff'
            }
          }
        })
      };

      try {
        const response = await window.fetch(url, options);

        if (response.ok) return true;

        const body = await response.json();
        this.errors = createErrorsFromApiResponse(body);

        return false;
      } catch (errors) {
        Logger.error('Error paying off loan account', JSON.stringify(errors));

        this.errors = createModelErrors({ base: 'APP_NETWORK_ERROR' });

        return false;
      }
    },
    async transition(event: LoanEvent, params: Note | null | DisbursalInfo) {
      const url = `${Loan.url(this.id)}/state`;
      const attributes = pipe(
        filterEmptyValues,
        snakeCaseObjectKeys
      )({
        event,
        ...params
      });

      const options = {
        ...Loan.fetchOptions(),
        method: 'PUT',
        body: JSON.stringify({ data: { attributes } })
      };

      try {
        const response = await window.fetch(url, options);
        if (!response.ok) {
          const body = await response.json();
          this.errors = createErrorsFromApiResponse(body);

          return false;
        }

        return true;
      } catch (errors) {
        Logger.error('Error transitioning loan state', JSON.stringify(errors));
        this.errors = createModelErrors({
          base: 'APP_NETWORK_ERROR'
        });

        return false;
      }
    },
    async topup(payload: TopupPayload) {
      const url = `${Base.baseUrl}/loans/${this.id}/topup`;
      const opts = Loan.fetchOptions();

      try {
        const response = await axios.post<any, { data: { data: { id: string } } }>(url, payload, opts);
        return response.data.data;
      } catch (error) {
        this.errors = httpErrorHandler.generateError(error);
        return null;
      }
    },
    guarantorPDFfilename() {
      return join('_', ['loan', this.id, 'guaranteed_by']);
    },
    downloadGuarantorPDF() {
      const loanId = this.id;

      const opts = Loan.fetchOptions();
      const options = blobHeaders(opts);

      const fileName = this.guarantorPDFfilename();
      const url = `${Loan.url()}/${loanId}/guarantors.pdf`;

      return Base.downloadFileFromUrl(url, options, fileName);
    },
    downloadApplicationPdf() {
      const loanId = this.id;

      const opts = Loan.fetchOptions();
      const options = blobHeaders(opts);

      const fileName = ['loan', loanId, 'application'].join('_');
      const url = `${Loan.url()}/${loanId}.pdf`;

      return Base.downloadFileFromUrl(url, options, fileName);
    },
    downloadSchedulePdf() {
      const loanId = this.id;

      const opts = Loan.fetchOptions();
      const options = blobHeaders(opts);

      const url = `${Schedule.url()}/${loanId}.pdf`;

      return Base.downloadFileFromUrl(url, options);
    }
  }
});

Loan.prototype._originalLoanSave = Loan.prototype.save;

Loan.prototype.save = async function(...args) {
  const repaymentInstallments = calculateNumberInstallments({
    loanDuration: Number(this.loanDuration),
    period: Number(this.repaymentPeriod)
  });

  // The 2 fields for which we must force the change
  this.repaymentPeriod = Number(this.repaymentPeriod);
  this.repaymentInstallments = repaymentInstallments;

  try {
    return await this._originalLoanSave(...args);
  } catch (saveError) {
    try {
      const body = await saveError.response.clone().json();
      this.errors = createErrorsFromApiResponse(body);
    } catch (parseError) {
      throw saveError;
    }
  }
};

export type TransactionT = {
  account: string;
};

export type LoanId = string;

export type PenaltiesT = {
  balance: number;
  due: number;
  paid: number;
};

export type FeesT = {
  balance: number;
  due: number;
  paid: number;
};

export type InterestT = {
  balance: number;
  due: number;
  paid: number;
};

export type PrincipalT = {
  balance: number;
  due: number;
  paid: number;
};

export type InterestSettingsT = {
  interestRate: number;
  interestApplicationMethod: 'REPAYMENT_DUE_DATE' | 'AFTER_DISBURSEMENT';
  interestCalculationMethod: 'DECLINING_BALANCE_DISCOUNTED' | 'DECLINING_BALANCE' | 'FLAT';
  interestChargeFrequency: 'ANNUALIZED';
  interestRateSource: 'FIXED_INTEREST_RATE';
};

export type InterestRateT = {
  percentage: number;
  chargeFrequency: string;
  calculationMethod: string;
};

export interface LoanType extends Omit<InferredModel<LoanType>, 'errors'> {
  name?: string;
  product?: LoanProductType;
  totalBalance: number;
  accruedInterest: number;
  amount: number;
  duration: string;
  errors?: ModelErrors;
  loanDuration: string;
  guarantees: GuaranteeType[];
  guarantors: GuaranteeType[];
  guarantorshipRequests: PotentialGuarantorType[];
  loanApplication?: LoanApplicationT;
  interest: InterestT;
  fees: FeesT;
  penalties: PenaltiesT;
  principal: PrincipalT;
  repaymentPeriod: number | ApiRepaymentPeriodT;
  repaymentPeriodUnit: PeriodUnitsT;
  repaymentFrequency: string;
  repayments: RepaymentType[];
  collaterals: CollateralT[];
  payOffLoans?: string[];
  state: {
    current: LoanState;
  };
  remittance: LoanRemittanceT;
  disbursementMode: string;
  disbursementDate: string;
  pendingDisbursementAmount: number;
  anticipatedDisbursementDate: string;
  anticipatedFirstRepaymentDate: string;
  submittedAt: string;
  disbursementBankDetails: DisbursementBankDetailsT;
  transactions: TransactionT[];
  purpose: string;
  specification: string;
  feeApplications: FeeApplicationT[];
  interestRate: InterestRateT;
  interestSettings: InterestSettingsT;
  totalPaid: string;
  member?: MemberType;
  //v1_api only
  memberName?: string;
  disbursedAt?: string;
  recommendedAmount?: number;
  disbursedAmount?: number;
  inArrears: boolean;
  expectedLoanRefinancings: LoanRefinancingT[];
  feesAtDisbursementCharged: string;
  totalExpectedAmountToRefinance: string;
  expectedNetDisbursalAmount: string;
  annualInterestRate: number;

  getOutstandingBalance: () => number;
  includes: (p: IncludesT) => LoanType;
  find: (p: string) => Promise<{ data: LoanType }>;
  disburse: (info: DisbursalInfo) => Promise<string | null>;
  disburseV1(info: DisbursalInfo): Promise<string | null>;
  writeOff: (notes: Note | null) => Promise<boolean>;
  canDisburse: (maxDisbursers: 1 | 2) => boolean;
  canWriteOff: () => boolean;
  canReschedule: () => boolean;
  canRefinance: () => boolean;
  canMakeRepayment: () => boolean;
  canTopup: () => boolean;
  canUndoTopup: () => boolean;
  hasNoGuarantors: () => boolean;
  hasNoCollaterals: () => boolean;
  hasNoSecurities: () => boolean;
  interestRatePercent: () => number;
  isEditable: () => boolean;
  isPaidOff: () => boolean;
  isApproved: () => boolean;
  isClosed: () => boolean;
  isBridgingProduct: () => boolean;
  isRefinancing: () => boolean;
  isPendingApproval: () => boolean;
  hasDisbursementRecord: () => boolean;
  canAddFee: () => boolean;
  downloadGuarantorPDF: () => Promise<boolean>;
  downloadSchedulePdf: () => Promise<boolean>;
  downloadApplicationPdf: () => Promise<boolean>;
  topup(payload: TopupPayload): Promise<boolean>;
  softReject(payload?: { comment?: string | null }): Promise<boolean>;
  approve(payload: {
    disbursement_type: null | 'normal' | 'early_release' | 'staggered';
    recommended_amount: null | number;
    anticipated_first_repayment_date: null | Date | string;
    anticipated_disbursement_date: null | Date | string;
    application_notes?: Array<{
      flow: string;
      step: string;
      section: string;
      value: any;
    }>;
  }): Promise<boolean>;
  reject(payload?: { comment?: null | string }): Promise<boolean>;
  payOff(params: any): Promise<boolean>;
  v1PayOff(params: any): Promise<boolean>;
}

export default Loan;
